import React from 'react';
import Button from '../../../components/Button.js';
import Input from '../../../components/Input.js';
import './AddLocationForm.css';
class AddLocationForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        locationId : null,
        apiKey : null,
        isOpen : false
      }
    }
    onInputChange(event) {
      const target = event.target;
      const locationId = this.state.locationId;
      const apiKey = this.state.apiKey;
      if (target.name === 'location-id') {
        this.setState({
          locationId : target.value,
          apiKey : apiKey
        });
      } else if (target.name === 'api-key') {
        this.setState({
          locationId : locationId,
          apiKey : target.value
        });
      }
    }
    render() {
      let refFunc;
      if (this.props.locations.length === 0) {
        refFunc = (element) => {
          element.focus();
        }
      }
      if (this.state.isOpen) {
        return (
          <div className = 'add-location-form'>
            <Button
              name = 'toggle-add-location-form'
              onClick = {() => {
                const state = this.state;
                state.isOpen = false;
                this.setState(state);
              }}
              className = 'toggle-add-location-form close'
              value = 'X'
            ></Button>
            <Input
              refFunc = {refFunc}
              type = 'text'
              name = 'location-id'
              placeholder = 'Location ID'
              onChange = {(event) => {
                this.onInputChange(event);
              }}
            ></Input>
            <Input
            type = 'text'
            name = 'api-key'
            placeholder = 'API Key'
            onChange = {(event) => {
              this.onInputChange(event);
            }}
            ></Input>
            <Button
            name = 'add-location'
            onClick = {() => {
              this.props.addLocation(this.state.locationId, this.state.apiKey);
              const state = this.state;
              state.isOpen = false;
              this.setState(state);
            }}
            className = 'add-location'
            value = 'Add Location'></Button>
          </div>
        );
      } else {
        return (
          <Button
            name = 'toggle-add-location-form'
            onClick = {() => {
              const state = this.state;
              state.isOpen = true;
              this.setState(state);
            }}
            className = 'toggle-add-location-form open'
            value = '+'
          ></Button>
        );
      }
    }
  }
  export default AddLocationForm;