import React from 'react';
import './DropdownMenu.css';
class DropdownMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterString : ''
        }
    }
    renderItem(valueObj, index) {
        return (
            <div
            data-id = {valueObj.id}
            className = 'item'
            key = {index}
            onClick = {() => {
                this.setState({
                    selected : valueObj
                })
                this.props.changeSelected(valueObj);
                const newState = Object.assign(this.state);
                newState.filterString = valueObj.name;
                this.setState(newState);
            }}>
                {valueObj.name}
            </div>
        );
    }
    render() {
        let selectedName = null
        if (this.props.selected !== undefined) {
            selectedName = this.props.selected.name
        }
        let items = this.props.items;
        let filteredItems = [];
        for (const item of items) {
            if (item.name.toLowerCase().includes(this.state.filterString.toLowerCase())) {
                filteredItems.push(item);
            }
        }
        if(filteredItems) {
            items = filteredItems.map((value, index) => {
                return this.renderItem(value, index);
            });
        }
        return (
            <div className = 'dropdown'>
                <div class = 'selected'>
                    <input
                        placeholder = 'Type here'
                        type = 'text'
                        onChange = {(event) => {
                            const tmpState = Object.assign({}, this.state);
                            tmpState.filterString = event.target.value;
                            this.setState(tmpState);
                        }}
                        value = {this.state.filterString}
                        className = 'selected'
                    />
                </div>
                <div className = 'content'>
                    {items}
                </div>
            </div>
        );
    }
}
export default DropdownMenu