import React from 'react';
class Input extends React.Component {
    constructor(props) {
      super(props)
      this.input = React.createRef();
    }
    componentDidMount() {
      if (this.props.refFunc) {
        this.props.refFunc(this.input.current);
      }
    }
    render() {
      return (
        <div className = 'input'>
          <input
          ref = {this.input}
          type = {this.props.type}
          name = {this.props.name}
          onChange = {this.props.onChange}
          placeholder = {this.props.placeholder}
          onKeyDown = {this.props.onKeyDown}
          value = {this.props.value}
          >
          </input>
        </div>
      )
    }
  }
  export default Input;