import React from 'react';
import { Link } from 'react-router-dom';
import './SectionSelectionPanel.css';
class SectionSelectionPanel extends React.Component {
    render() {

        return (
            <div className = 'section-selection-panel'>
                <Link to = "/">
                    <div className = 'section-selector'
                        onClick = {(event) => {
                            this.props.updateSection('custom-code')
                        }}
                    >
                        Custom Code
                    </div>
                </Link>
                <Link to = "/location-customizer">
                    <div className = 'section-selector'
                        onClick = {(event) => {
                            this.props.updateSection('location-customizer');
                        }}
                    >
                        Location Custimizer
                    </div>
                </Link>
                <Link to = "/code-generator">
                    <div className = 'section-selector'
                        onClick = {(event) => {
                            this.props.updateSection('code-generator');
                        }}
                    >
                        Code Generator
                    </div>
                </Link>
            </div>
        );
    }
}
export default SectionSelectionPanel;