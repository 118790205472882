import React from 'react';
import ResetPasswordForm from './components/ResetPasswordForm.js';
class ResetPasswordApp extends React.Component {
    render() {
        return (
            <div className = 'reset-password-app'>
                <ResetPasswordForm>

                </ResetPasswordForm>
            </div>
        );
    }
}
export default ResetPasswordApp;