import React from 'react';
import LoginForm from './components/LoginForm.js';
class LoginApp extends React.Component {
  render() {
    return (
      <div className = 'login-app'>
        <LoginForm
          className = 'login form'
          toggleForgotPasswordForm = {() => {
            this.toggleForgotPasswordForm();
          }}
          updateUser = {(user) => {
          this.props.updateUser(user);
          }}
        ></LoginForm>
      </div>
    )
  }
}
export default LoginApp;