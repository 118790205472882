import React from 'react';
import Input from '../../../components/Input.js';
import Button from '../../../components/Button.js';
import { Outlet } from 'react-router-dom';
class ForgotPasswordForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email : null,
      }
    }
    onInputChange(event) {
      const target = event.target;
      const username = this.state.username;
      const password = this.state.password;
      if (target.name === 'email') {
        this.setState({
          email : target.value,
        });
      }
    }
    onButtonClick() {
      const fetchUserId = async () => {
        try {
          const response = await fetch(`https://api.ghlmanager.com/reset-password?email=${this.state.email}`, {
            method : 'GET',
            headers : {
              'content-type' : 'application/json'
            },
          });
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
      fetchUserId();
    }
    render() {
        let className = 'forgot-password form'
        if (this.props.className) {
            className += ' ' + this.props.className;
        }
      return (
        <div className = {className}>
          <Input 
            refFunc = {(element) => {
              element.focus();
            }}
            type = 'text' 
            name = 'email' 
            placeholder = 'Email'
            onChange = {(event) => {
              this.onInputChange(event);
            }}
          ></Input>
          <Button
            className = 'send-link'
            onClick = {() => {
              this.onButtonClick();
            }}
            value = 'Send Reset Password Link'
          >
          </Button>
        </div>      
      )
    }
  }
  export default ForgotPasswordForm;