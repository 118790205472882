import React from 'react';
import Button from './Button.js';
class NavigatorBar extends React.Component {
    render() {
      let logoutButton;
      let className = 'navigator-bar ';
      if (this.props.user) {
        className += 'user';
        logoutButton = (
          <Button
            className = 'logout'
            value = 'Logout'
            onClick = {() => {
              this.props.updateUser(null)
              document.cookie = 'user_id=; expires=2020-01-01 00:00:00';
            }
            }
            name = 'logout'
          >
          </Button>
        )
      }
      return (
        <nav className = {className}>
          <div className = 'title'>
            GHL Manager
          </div>
            {logoutButton}
        </nav>
      )
    }
  }
  export default NavigatorBar;