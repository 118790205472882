import React from 'react';
class Button extends React.Component {
    render() {
      const className = 'button ' + this.props.className;
      return (
        <div className = {className}>
          <button
            name = {this.props.name}
            onClick = {this.props.onClick}
          >
            {this.props.value}
          </button>
        </div>
      )
    }
  }
  export default Button;