import React from 'react';
class LocationSelector extends React.Component {
    render() {
      const className = 'location-selector ' + this.props.className
      return (
        <div 
          className = {className}
          onClick = {(event) => {
            this.props.onClick(event.target.getAttribute('name'));
          }}
          name = {this.props.location.id}
        >
          {this.props.location.id}
        </div>
      );
    }
  }
  export default LocationSelector;