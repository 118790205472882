import React from 'react';
import './AuthenticatedApp.css';
import SectionSelectionPanel from './components/SectionSelectionPanel.js';
import CodeGeneratorSection from './sections/CodeGeneratorSection/CodeGeneratorSection';
import CustomCodeSection from './sections/CustomCodeSection/CustomCodeSection.js';
import LocationCustomizerPanel from './sections/LocationCustomizerPanel/LocationCustomizerPanel.js';
import LocationSelectionPanel from './components/LocationSelectionPanel.js';
import { Outlet, Routes, Route } from 'react-router-dom';
class AuthenticatedApp extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        locations : [],
        selectedLocation : null,
        sectionName : null,
        hasLoaded : false,
      }
    }
    componentDidMount() {
      const waitForLocationAndUserData = async () => {
        await this.fetchAllLocationIdsForUser();
        await this.fetchAllLocationData();
      }
      waitForLocationAndUserData().then(() => {
        const state = this.state;
        state.hasLoaded = true;
        this.setState(state);
      });
    }
    async fetchAllLocationIdsForUser() {
      try {
        const requestResult = await fetch('https://api.ghlmanager.com/locations?user_id=' + this.props.user.id, {
          'method' : 'get',
          'headers' : {
              'content-type' : 'application/json'
          }
        }).then((result) => {
          return result.json();
        });
        if (requestResult.status === 'error') {
            throw requestResult;
        }
        const locations = [];
        console.log(requestResult);
        requestResult.data.forEach((dataPacket) => {
          const location = {};
          location.id = dataPacket['location_id'];
          location.apiKey = dataPacket['api_key'];
  
          locations.push(location);
        });
        this.setState({
          locations : locations,
          selectedLocation : locations[0] ? 0 : null
        })
      } catch (error) {
        console.error(error);
      }
    }
    addLocation(id, apiKey) {
      const state = Object.assign({}, this.state);
      if (state.selectedLocation === null) {
        state.selectedLocation = 0;
      }
      fetch('https://api.ghlmanager.com/location', {
          'method' : 'post',
          'headers' : {
              'content-type' : 'application/json'
          },
          'body' : JSON.stringify({'user_id' : this.props.user.id, 'location_id' : id, 'api_key' : apiKey})
      }).then((result) => {
          return result.json();
      }).then((result) => {
          console.log(result);
          return result.status === 'success';
      }).then((success) => {
        if (success) {
          state.locations = state.locations.concat([
            {id : id, apiKey : apiKey}
          ])
          this.setState(state);
        }
      }).catch((error) => {
          console.log(error);
      })
    }
    updateSelectedLocation(selectedLocation) {
      console.log(selectedLocation);
      const state = Object.assign({}, this.state);
      const locations = state.locations;
      locations.forEach((location, index) => {
        if (location.id === selectedLocation.id) {
          state.selectedLocation = index;
        }
      });
  
      this.setState(state)
    }
    onSelectedLocationCustomJsChange(event) {
      const state = Object.assign({}, this.state);
      const locations = state.locations.map(x => x);
      const selectedLocation = state.selectedLocation;
      locations[selectedLocation].customJs = event.target.value;
      state.locations = locations;
      this.setState(state);
    }
    onSelectedLocationCustomCssChange(event) {
      const state = Object.assign({}, this.state);
      const locations = state.locations.map(x => x);
      const selectedLocation = state.selectedLocation;
      locations[selectedLocation].customCss = event.target.value;
      state.locations = locations;
      this.setState(state);
    }
    updateLocation(newLocation) {
      fetch('https://api.ghlmanager.com/location', {
          'method' : 'put', 
          'headers' : {
              'content-type' : 'application/json'
          },
          'body' : JSON.stringify({'location_id' : newLocation.id, 'user_id' : this.props.user.id, 'custom_js' : newLocation.customJs, 'custom_css' : newLocation.customCss})
      }).then((result) => {
          return result.json();
      }).then((result) => {
          console.log(result);   
          const state = Object.assign({}, this.state);
          const locations = Array.from(state.locations);
          locations.forEach((oldLocation, index) => {
            if (oldLocation.id === newLocation.id) {
              locations[index] = Object.assign({},newLocation);
            }
          });
          state.locations = locations;
          this.setState(state);
      }).catch((error) => {
          console.log(error);
      });
    }
    fetchLocationData(locationId) {
      return fetch('https://api.ghlmanager.com/location?location_id=' + locationId + '&user_id=' + this.props.user.id, 
        {
          'method' : 'get',
          'headers' : {
            'content-type' : 'application/json'
          }
      }).then(result => result.json()).then(requestResult => {
        if (requestResult.status === 'error') {
          return Promise.reject(requestResult.data);
        }
        console.log(requestResult);
        return Promise.all([fetch('https://rest.gohighlevel.com/v1/locations/' + requestResult.data.id, {
          'method' : 'GET',
          'headers' : {
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + requestResult.data.api_key
          },
        }).then(result => result.json()), Promise.resolve(requestResult.data)]);
      }).then(promiseArray => {
        const location = promiseArray[0];
        location.apiKey = promiseArray[1]['api_key'];
        location.customJs = promiseArray[1]['custom_js'];
        location.customCss = promiseArray[1]['custom_css'];

        return Promise.resolve(location);
      });
    }
    async fetchAllLocationData() {
      try {
        const state = Object.assign({}, this.state);
        const oldLocations = state.locations.map(x => x);
        const requests = [];
        oldLocations.forEach((location) => {
          requests.push(this.fetchLocationData(location.id));
        });
        const newLocations = await Promise.all(requests);
        const finalLocations = [];
        for (const location of newLocations) {
          if (location.name !== undefined && location.id !== undefined && location.apiKey !== undefined) {
            finalLocations.push(location);
          }
        }
        console.log(finalLocations);
        state.locations = finalLocations;
        this.setState(state);
      } catch (error) {
        console.error(error);
      }
    }
    render() {
      const state = Object.assign({}, this.state);
      const locations = state.locations;
      const sectionName = state.sectionName;
      const selectedLocation = state.selectedLocation;
      console.log(locations);
      let section = null;
      if (!state.hasLoaded) {
        return (
          <div className = 'authenticated-app'>
          </div>
        );
      } 
      return (
        <div className = 'authenticated-app'>
          <SectionSelectionPanel
            updateSection = {
              (sectionName) => {
                 const state = this.state;
                 state.sectionName = sectionName;
                 this.setState(state);
              }
            }></SectionSelectionPanel>
            <LocationSelectionPanel
            locations = {this.state.locations}
            addLocation = {(id, apiKey) => {
              this.addLocation(id, apiKey);
            }}
            selectedLocation = {this.state.selectedLocation}
            updateSelectedLocation = {(selectedLocation) => {
              this.updateSelectedLocation(selectedLocation);
            }}
          ></LocationSelectionPanel>
          <Routes>
            <Route path = "/" element = {<CustomCodeSection
              location = {locations[selectedLocation]}
              updateLocation = {(location) => {
                this.updateLocation(location);
              }}
              fetchAllLocationData = {() => {
                return this.fetchAllLocationData();
              }}
              fetchAllLocationIdsForUser = {() => {
                return this.fetchAllLocationIdsForUser();
              }}
              onSelectedLocationCustomJsChange = {(event) => {
                this.onSelectedLocationCustomJsChange(event);
              }}
              onSelectedLocationCustomCssChange = {(event) => {
                this.onSelectedLocationCustomCssChange(event);
              }}
              updateSelectedLocation = {(selectedLocationId) => {
                this.updateSelectedLocation(selectedLocationId);
              }}
              userId = {this.props.user.id}
              updateLocation = {(locationObj) => {
                this.updateLocation(locationObj);
              }}
            ></CustomCodeSection>} />
            <Route path = "code-generators" element = {<CodeGeneratorSection></CodeGeneratorSection>} />
            <Route path = "location-customizer" element = {<LocationCustomizerPanel
              location = {this.state.locations[this.state.selectedLocation]}>
            </LocationCustomizerPanel>} />
          </Routes>
          <Outlet />
        </div>
      )
    }
  }
  export default AuthenticatedApp;