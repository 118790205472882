import React from 'react';
import { Outlet } from 'react-router-dom';
class CodeGeneratorSection extends React.Component {
    render() {
        return (
            <section className = 'code-generator'>
                Code generators are coming soon
                <Outlet />
            </section>
        )
    }
}
export default CodeGeneratorSection;