import React from 'react';
import Input from '../../../components/Input.js';
import GoogleLogin from 'react-google-login';
import Button from '../../../components/Button.js';
import ForgotPasswordForm from '../../ForgotPasswordApp/components/ForgotPasswordForm.js';
class LoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        username : null,
        password : null,
      }
    }
    verifyLogin(username, password) {
      return new Promise((resolve, reject) => {
        fetch('https://api.ghlmanager.com/login/verify_password', {
          'method' : 'post',
          'headers' : {
            'content-type' : 'application/json'
          },
          'body' : JSON.stringify({
            'username' : username,
            'password' : password
          })
        }).then((result) => {
          return result.json();
        }).then((result) => {
          console.log(result);
                      if (result['status'] === 'success') {
                          document.cookie = 'user_id=' + result['data']['id']
                          resolve(result['data']);
                      } else {
                          reject(result['data']);
                      }
        }).catch((error) => {
          reject(error);
        });
      });
    }
    onInputChange(event) {
      const target = event.target;
      const username = this.state.username;
      const password = this.state.password;
      if (target.name === 'username') {
        this.setState({
          username : target.value,
          password : password
        });
      } else if (target.name === 'password') {
        this.setState({
          username : username,
          password : target.value
        });
      }
    }
    onButtonClick() {
      const fetchUserId = async () => {
        try {
          const user = await this.verifyLogin(this.state.username, this.state.password);
          document.cookie = 'user_id=' + user['id'];
          this.props.updateUser(user);
          console.log(user);
        } catch (error) {
          console.log(error);
        }
      }
      fetchUserId();
    }
    render() {
      let className = 'login form';
      if (this.props.className) {
        className += ' ' + this.props.className;
      }
      return (
        <div className = {className}>
          <Input 
            refFunc = {(element) => {
              element.focus();
            }}
            type = 'text' 
            name = 'username' 
            placeholder = 'Username'
            onChange = {(event) => {
              this.onInputChange(event);
            }}
            onKeyDown = {(event) => {
              if (event.keyCode === 13) {
                this.onButtonClick();
              }
            }}
          ></Input>
          <Input 
            type = 'password' 
            name = 'password'
            placeholder = 'Password'
            onChange = {(event) => {
              this.onInputChange(event);
            }}
            onKeyDown = {(event) => {
              if (event.keyCode === 13) {
                this.onButtonClick();
              }
            }}
          ></Input>
          <Button
            className = 'login'
            onClick = {() => {
              this.onButtonClick();
            }}
            value = 'Login'
          >
          </Button>
          <div className = 'forgot-password'>
            <a
            onClick = {(event) => {
              window.location.pathname = '/forgot-password';
            }
            }
            >Forgot Password?</a>
          </div>
          <GoogleLogin
                clientId = '480764079423-jhaomnl9ghun5e6s8l4sfedtq5huire3.apps.googleusercontent.com'
                buttonText="Login with Google"
                onSuccess={async (response) => {
                    const user = await this.verifyLogin(response.profileObj.email, response.profileObj.googleId);
                    document.cookie = 'user_id=' + user['id'];
                    console.log(user);
                }}
                onFailure={(event) => {
                    console.log(event);
                }}
                cookiePolicy={'single_host_origin'}
            />
        </div>
      )
    }
  }
  export default LoginForm;