import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";



import NavigatorBar from './components/NavigatorBar';
import AuthenticatedApp from './pages/AuthenticatedApp/AuthenticatedApp.js';
import LoginApp from './pages/LoginApp/LoginApp.js';
import ForgotPasswordApp from './pages/ForgotPasswordApp/ForgotPasswordApp.js';
import ResetPasswordApp from './pages/ResetPasswordApp/ResetPasswordApp.js';
import GhlOath from './pages/GhlOath/GhlOath';

class Root extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user : null,
        page : null,
      }
    }
    getUserFromCookie() {
      return new Promise((resolve, reject) => {
        let cookie_string = document.cookie;
        const id_position = cookie_string.indexOf('user_id');
        
        if (id_position === -1) {
          reject('did not find user_id cookie');
        } else {
          cookie_string = cookie_string.substring(id_position);
          let id_end = cookie_string.indexOf(';');
          if (id_end === -1) {
            id_end = cookie_string.length;
          }
          const user_id = cookie_string.substring(cookie_string.indexOf('=') + 1, id_end);
          fetch('https://api.ghlmanager.com/user?id=' + user_id, {
            'method' : 'get',
            'headers' : {
              'content-type' : 'application/json'
            }
          }).then((result) => {
            return result.json();
          }).then((result) => {
            if (result['status'] === 'success') {
              resolve(result['data']);
            } else {
              reject(result['data']);
            }
          }).catch((error) => {
            reject(error);
          });
        }
      });
    }
    componentDidMount() {
      const fetchUser = async () => {
        try {
          const state = this.state;
          const user = await this.getUserFromCookie();
          state.user = user;
          this.setState(state);
          console.log(user);
        } catch (error) {
          console.log(error);
        }
      }
      fetchUser().then(() => {
        const state = this.state;
        state.page = window.location.hash.substring(1);
        this.setState(state);
      });
    }
    updateUser(user) {
      const state = this.state;
      state.user = user;
      this.setState(state);
    }
    render() {
      let defaultApplication = null;
      if (this.state.user !== null) {
        defaultApplication = (
          <AuthenticatedApp
            user = {this.state.user}
            updateUser = {(user) => {
              this.updateUser(user);
            }}
          ></AuthenticatedApp>
        );
    } else {
      console.log(this.state.user)
      defaultApplication = (
        <LoginApp
        updateUser = {(user) => {
          this.updateUser(user);
        }}
        >
        </LoginApp>
      )
    }
      return (
        <div className = 'app'>
          <NavigatorBar
            user = {this.state.user}
            updateUser = {() => {
              this.updateUser();
            }}
          ></NavigatorBar>
          <BrowserRouter>
            <Routes>
              <Route path = "/*" element = {defaultApplication} />
              <Route path = "/forgot-password" element = {<ForgotPasswordApp />} />
              <Route path = "/reset-password" element = {<ResetPasswordApp />} />
              <Route path = "/login" element = {<LoginApp
                updateUser = {(user) => {
                  this.updateUser(user);
                }}
                >
                </LoginApp>}
              />
              <Route path = "/oath/callback/gohighlevel" element = {<GhlOath />} />
            </Routes>
          </BrowserRouter>
        </div>
      );
    }
  }
  ReactDOM.render(<Root />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
