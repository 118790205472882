import React from 'react';
import CustomCodeEditorPanel from './components/CustomCodeEditorPanel.js';
import { Outlet } from 'react-router-dom';
import './CustomCodeSection.css';
class CustomCodeSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeScripts : [],
      scripts : []
    }
  }
  async fetchAllScriptsForUser() {
    const userId = this.props.userId;
    const state = Object.assign({}, this.state);

    fetch('https://api.ghlmanager.com/custom-scripts?user_id=' + userId, {
      headers : {
          'Content-Type' : 'application/json'
      },
      method : 'GET'
    }).then(result => result.json()).then(response => {
      if (response.status === 'error') {
        return Promise.reject(response.data);
      }
      state.scripts = state.scripts.concat([
          {id : 0, type : 'text/javascript', name : this.props.location.name + ' CustomJs', content : this.props.location.customJs},
          {id : 1, type : 'text/css', name : this.props.location.name + ' CustomCss', content : this.props.location.customCss},
      ]);
      state.scripts = state.scripts.concat(response.data);
      this.setState(state);
    });
  }
  componentDidMount() {
    this.fetchAllScriptsForUser();
  }
  renderCustomCodeEditorPanel(index) {
    if (this.state.scripts[0]) {
      if (this.state.scripts[0].name !== this.props.location.name + ' CustomJs' && this.state.scripts[1].name !== this.props.location.name + ' CustomCss') {
        const state = Object.assign({}, this.state);
        const scripts = Array.from(state.scripts);
        scripts[0] = {id : 0, type : 'text/javascript', name : this.props.location.name + ' CustomJs', content : this.props.location.customJs};
        scripts[1] = {id : 1, type : 'text/css', name : this.props.location.name + ' CustomCss', content : this.props.location.customCss};
        state.scripts = scripts;
        this.setState(state);
      }
    }
    return (
      <CustomCodeEditorPanel
        key = {index}
        scripts = {this.state.scripts}
        onScriptOpen = {(scriptObj) => {
          const state = Object.assign({}, this.state);
          console.log(state);
          const activeScripts = Array.from(this.state.activeScripts);
          activeScripts.push(scriptObj);
          state.activeScripts = activeScripts;
          this.setState(state);
        }}
        onScriptClose = {(scriptObj) => {
          const state = Object.assign({}, this.state);
          console.log(state);
          const activeScripts = Array.from(this.state.activeScripts);
          activeScripts.forEach((activeScript, index) => {
            if (activeScript.id === scriptObj.id) {
              activeScripts.splice(index, 1);
            }
          });
          state.activeScripts = activeScripts;
          this.setState(state);
        }}
        userId = {this.props.userId}
        location = {this.props.location}
        updateLocation = {(locationObj) => {
          this.props.updateLocation(locationObj);
        }}
      ></CustomCodeEditorPanel>
    );
  }
  render() {
    const customCodeEditorPanels = this.state.activeScripts.map((script, index) => this.renderCustomCodeEditorPanel(index));
    customCodeEditorPanels.push(this.renderCustomCodeEditorPanel(customCodeEditorPanels.length));
    return (
      <section className = 'custom-code'>
        {customCodeEditorPanels}
        <Outlet />
      </section>
    )
  }
}
export default CustomCodeSection;