import React from 'react';
import Editor from "@monaco-editor/react";
import './CustomCodeEditor.css';

class CustomCodeEditor extends React.Component {
    constructor(props) {
      super(props)
      this.textarea = React.createRef();
    }
    componentDidMount() {
      if (this.props.refFunc) {
        this.props.refFunc(this.textarea.current);
      }
    }
    render() {
      let defaultValue = null
      let defaultLanguage = null;
      if (this.props.codeType === 'text/javascript') {
        defaultValue = 'Custom Javascript'
        defaultLanguage = "javascript"
      } else if (this.props.codeType === 'text/css') {
        defaultValue = 'Custom CSS'
        defaultLanguage = "css";
      }
      if (this.props.code !== null && this.props.code !== "") {
        defaultValue = this.props.code;
      }
      return (
        <div className = 'custom-code-editor'>
          <Editor
            ref = {this.textarea}
            onChange = {(value, event) => {
              this.props.onChange(value, event);
            }}
            onKeyDown = {(event) => {
              if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                this.props.onSave(event);
                event.stopPropagation();
              }
            }}
            defaultLanguage = {defaultLanguage}
            defaultValue = {defaultValue}
          ></Editor>
        </div>
      )
    }
  }
  export default CustomCodeEditor;