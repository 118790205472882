import React from 'react';
import LocationSelector from './LocationSelector.js';
import AddLocationForm from './AddLocationForm.js';
import DropdownMenu from '../../../components/DropdownMenu.js';
import './LocationSelectionPanel.css';
class LocationSelectionPanel extends React.Component {
    renderLocationSelector(i) {
      const locations = this.props.locations;
      const location = locations[i];
      if (i === this.props.selectedLocation) {
        return (
          <LocationSelector
            key = {location.id}
            className = 'selected'
            location = {location}
            onClick = {(selectedLocationID) => {
              this.props.updateSelectedLocation(selectedLocationID)}
            }
          ></LocationSelector>
        )
      }
      return (
        <LocationSelector
          key =  {location.id}
          className = ''
          location = {location}
          onClick = {(selectedLocationID) => {
            this.props.updateSelectedLocation(selectedLocationID)}
          }
        ></LocationSelector>
      )
    }
    render() {
      const locations = Array.from(this.props.locations);
      return (
        <div className = 'location-selection-panel'>
          <DropdownMenu
            changeSelected = {
              (selectedLocationID) => {
                this.props.updateSelectedLocation(selectedLocationID)
              }
            }
            selected = {locations[this.props.selectedLocation]}
            items = {locations}
          ></DropdownMenu>
          <AddLocationForm
            locations = {this.props.locations}
            addLocation = {(id, apiKey) => {
              this.props.addLocation(id, apiKey)}}
          ></AddLocationForm>
        </div>
      );
    }
  }
  export default LocationSelectionPanel;