import React from 'react';
import Button from '../../../components/Button.js';
import Input from '../../../components/Input.js';
import Utilities from '../../../Utilities.js';
class  ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password : null,
            passwordConfirm : null,
            errorMessage : null
        }
    }
    onInputChange(event) {
        const target = event.target;
        const password = this.state.password;
        const passwordConfirm = this.state.passwordConfirm;
        const errorMessage = this.state.errorMessage;
        if (target.name === 'password') {
          this.setState({
            password : target.value,
            passwordConfirm : passwordConfirm,
            errorMessage : errorMessage
          });
        } else if (target.name === 'password-confirm') {
          this.setState({
            password : password,
            passwordConfirm : target.value,
            errorMessage : errorMessage
          });
        }
      }
      onButtonClick() {
        const resetPassword = async () => {
            try {
                const password = this.state.password;
                const passwordConfirm = this.state.passwordConfirm;

                if (password === null) {
                    this.setState({
                        password : password, 
                        passwordConfirm : passwordConfirm,
                        errorMessage : 'Password is empty'
                    });
                  } else if (password != null && password!=passwordConfirm) {
                    this.setState({
                        password : password, 
                        passwordConfirm : passwordConfirm,
                        errorMessage : 'Passwords do not match'
                    });
                  } else if (password != null && password === passwordConfirm) {
                    this.setState({
                        password : password, 
                        passwordConfirm : passwordConfirm,
                        errorMessage : null
                    });
                    const queryParameters = await Utilities.queryStringToJSON(window.location.href);
                    console.log(queryParameters);
                    const id = queryParameters['json']['id'];
                    const token = queryParameters['json']['token'];
                    const response = await fetch('https://api.ghlmanager.com/reset-password', {
                        'method' : 'post',
                        'headers' : {
                            'content-type' : 'application/json'
                        },
                        'body' : JSON.stringify({id : id, token : token, password : password})
                    }).then(result => {
                        return result.json()
                    });

                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        }
        resetPassword();
      }
      render() {
        let className = 'reset-password form';
        if (this.state.errorMessage) {
          className += ' error';
        }
        return (
            <div className = {className}>
                <Input 
                    refFunc = {(element) => {
                        element.focus();
                    }}
                    type = 'password' 
                    name = 'password' 
                    placeholder = 'Password'
                    onChange = {(event) => {
                        this.onInputChange(event);
                    }}
                    onKeyDown = {(event) => {
                        if (event.keyCode === 13) {
                        this.onButtonClick();
                        }
                    }}
                ></Input>
                <Input 
                    type = 'password' 
                    name = 'password-confirm'
                    placeholder = 'Confirm Password'
                    onChange = {(event) => {
                        this.onInputChange(event);
                    }}
                    onKeyDown = {(event) => {
                        if (event.keyCode === 13) {
                        this.onButtonClick();
                        }
                    }}
                ></Input>
                <div className = 'error-message'>
                    {this.state.errorMessage}
                </div>
                <Button
                    className = 'reset-password'
                    onClick = {() => {
                        this.onButtonClick();
                    }}
                    value = 'Reset Password'
                ></Button>
            </div>
        );
    }
}
export default ResetPasswordForm;