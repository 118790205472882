const queryStringToJSON = (queryString) => {
    let output = {};
    output.url = queryString.substring(0,queryString.indexOf('?'))
    const query_parameters  = queryString.substring(queryString.indexOf('?') + 1, queryString.length).split('&');
    let parameter_json = {};
    query_parameters.forEach((parameter) => {
        const parameter_array = parameter.split('=');
        if (parameter_array.length != 2) {
            throw new Error('there was a missing query parameter');
        }
        parameter_json[parameter_array[0]] = parameter_array[1];
    });
    output.json = parameter_json;
    console.log(parameter_json);
    return output;
}
module.exports.queryStringToJSON = queryStringToJSON;