import React from 'react';
import CustomCodeEditor from './CustomCodeEditor.js';
import Button from '../../../../../components/Button.js';
import './CustomCodeEditorPanel.css';
import Input from '../../../../../components/Input.js';
class CustomCodeEditorPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openStage : 'closed',
      hasOpened : false,
      script : {name : '', type : '', content : ''}
    }
  }
  createNewScript() {
    const state = Object.assign({}, this.state);
    state.script.content = '';
    const body_json = this.state.script;
    body_json.user_id = this.props.userId;
    fetch('https://api.ghlmanager.com/custom-scripts', {
      method : 'POST',
      headers : {
        'Content-Type' : 'application/json'
      },
      body : JSON.stringify(body_json)
    }).then(result => result.json()).then(result => {
      if (result.status === 'error') {
        return Promise.reject(result.data);
      }
      state.script.id = result.data;
      state.openStage = 'script-open';
      this.setState(state);
      return Promise.resolve('new script created');
    })
  }
  saveScript() {
    //customJs
    if (this.state.script.id === 0) {
      const location = Object.assign({}, this.props.location);
      location.customJs = this.state.script.content;
      this.props.updateLocation(location);
    } else if (this.state.script.id === 1) {
      const location = Object.assign({}, this.props.location);
      location.customCss = this.state.script.content;
      this.props.updateLocation(location);
    } else {
      const state = Object.assign({}, this.state);
      const body_json = Object.assign({}, this.state.script);
      body_json.user_id = this.props.userId;
      body_json.script_id = body_json.id;
      fetch('https://api.ghlmanager.com/custom-scripts', {
        method : 'PUT',
        headers : {
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify(body_json)
      }).then(result => result.json()).then(result => {
        if (result.status === 'error') {
          return Promise.reject(result.data);
        }
        state.openStage = 'script-open';
        this.setState(state);
        return Promise.resolve('script updated');
      });
    }
  }
  renderMenuItem(scriptObj) {
    return (
      <div
        className = 'script-menu-item'
        data-script-id = {scriptObj.id}
        onClick = {(event) => {
          const state = Object.assign({}, this.state);
          state.script = scriptObj;
          state.openStage = 'script-open'
          this.setState(state);
        }}
      >
        {scriptObj.name}
      </div>
    );
  }
  render() {
    const openStage = this.state.openStage;
    let content = null;
    let className = '';
    if (openStage === 'closed') {
      content = (
        <Button
          onClick = {(event) => {
            const state = Object.assign({}, this.state);
            state.openStage = 'menu-open';
            this.setState(state);
          }}
          name = 'open-new-script'
          className = 'open-new-script'
          value = '+'
        ></Button>
      );
      className = ' closed';
    } else if (openStage === 'menu-open') {
      const scripts = Array.from(this.props.scripts);
      const scriptMenuItems = scripts.map(script => this.renderMenuItem(script));

      content = (
        <div className = 'script-menu'>
          {scriptMenuItems}
          <div
            className = 'script-menu-item add-new-script'
            onClick = {(event) => {
              const state = Object.assign({}, this.state);
              state.openStage = 'new-script-form';
              state.script.name = '';
              state.script.content = '';
              state.script.type = '';
              this.setState(state); 
            }}>
            Add New Script
          </div>
        </div>
      );
      className = ' menu-open';
    } else if (openStage === 'script-open') {
      content = [
        <header>
          <div className = 'title'>
            {this.state.script.name}
          </div>
          <div className = 'controller'>
          <Button
            key = 'change-size-button'
            value = '+'
            onClick = {(event) => {
              const target = event.target;

              const button = target.parentNode;
              if (button.classList.contains('maximize')) {
                target.innerHTML = '-'
              } else {
                target.innerHTML = '+'
              }
              const editorPanel = button.parentNode.parentNode.parentNode;
              
              button.classList.toggle('maximize');
              button.classList.toggle('minimize');
              editorPanel.classList.toggle('maximized');
            }}
            className = 'change-size maximize script-open'
          ></Button>
          <Button
            key = 'close-button'
            value = 'X'
            onClick = {(event) => {
              const state = Object.assign({}, this.state);
              if (state.hasOpened) {
                state.openStage = 'closed'
                this.props.onScriptClose(this.state.script);
                state.hasOpened = false;
                this.setState(state);
              }
            }}
            className = 'close script-open'
          ></Button>
        </div>
        </header>,
        <CustomCodeEditor
          key = 'custom-code-editor'
          onChange = {(value, event) => {
            const state = Object.assign({}, this.state);
            state.script.content = value;
            this.setState(state);
          }
          }
          onSave = {(event) => {
            this.saveScript();
          }
          }
          codeType = {this.state.script.type}
          code = {this.state.script.content}
        ></CustomCodeEditor>,
        <footer>
          <Button
            key = 'copy-link'
            className = 'copy-link'
            value = 'Copy Link'
            onClick = {(event) => {
              const scriptId = this.state.script.id;
              if (scriptId === 0 || scriptId === 1) {
                navigator.clipboard.writeText('https://api.ghlmanager.com/custom-js?user_id=' + this.props.userId);
              } else {
                navigator.clipboard.writeText('https://api.ghlmanager.com/custom-scripts?script_id=' + this.state.script.id + '&user_id=' + this.props.userId);
              }
              const customCodeEditorPanel = event.target.parentNode.parentNode.parentNode;
              const commentBox = customCodeEditorPanel.querySelector('footer div.comment-box');
              commentBox.innerHTML = 'Copied to Clipboard';
              window.setTimeout(() => {
                commentBox.innerHTML = '';
              }, 1000);
            }}
          ></Button>
          <div className = 'comment-box'>
          </div>
          <Button
            key = 'save-button'
            className = 'save'
            value = 'Save'
            onClick = {(event) => {
              this.saveScript();
              const customCodeEditorPanel = event.target.parentNode.parentNode.parentNode;
              const commentBox = customCodeEditorPanel.querySelector('footer div.comment-box');
              commentBox.innerHTML = 'Script Saved';
              window.setTimeout(() => {
                commentBox.innerHTML = '';
              }, 1000);
            }}
          ></Button>
        </footer>
      ];
      className = ' script-open';
      if (!this.state.hasOpened) {
        this.props.onScriptOpen(this.state.script);
        const state = Object.assign({}, this.state);
        state.hasOpened = true;
        this.setState(state);
      }
    } else if (openStage === 'new-script-form') {
      content = (
        <div className = 'new-script-form'>
          <Button
          key = 'close-button '
          value = 'X'
          onClick = {(event) => {
            const state = Object.assign({}, this.state);
            state.openStage = 'closed'
            this.props.onScriptClose(this.state.script);
            state.hasOpened = false;
            this.setState(state);
          }} 
          className = 'close new-script-form'
        ></Button>
          <Input 
            name = 'script-name'
            placeholder = 'Script Name'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.script.name = event.target.value;
              this.setState(state);
            }}
            value = {this.state.script.name}
          ></Input>
          <Input 
            name = 'script-type'
            placeholder = 'Script Type'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.script.type = event.target.value;
              this.setState(state);
            }}
            value = {this.state.script.type}
          ></Input>
          <Button
            onClick = {(event) => {
              this.createNewScript();
            }}
            value = 'Create New Script'
          ></Button>
        </div>
      );
    }
    return (
      <div className = {'custom-code-editor-panel' + className}>
        {content}
      </div>
    )
  }
}
export default CustomCodeEditorPanel;