import React from 'react';
import Input from '../../../../components/Input.js';
import Button from '../../../../components/Button.js';
import './LocationCustomizerPanel.css';
import { Outlet } from 'react-router-dom';
class LocationCustomizerPanel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        location : this.props.location,
      }
    }
    updateLocationInGoHighLevel() {
      fetch('https://rest.gohighlevel.com/v1/locations/' + this.state.location.id, {
        headers : {
          'Content-Type' : 'application/json',
          'Authorization' : 'Bearer ' + this.state.location.apiKey
        },
        method : 'PUT',
        body : JSON.stringify(this.state.location)
      }).then(result => result.json()).then(result => console.log(result));
    }
    componentDidMount() {
    }
    render() {
      /*"businessName": "Tesla inc",
    "address": "3500 Deer Creek Road",
    "city": "Palo Alto",
    "country": "US",
    "state": "CA",
    "postalCode": "94304",
    "website": "https://www.tesla.com",
    "timezone": "US/Central",
    "firstName": "John",
    "lastName": "Deo",
    "email": "john@deo.com",
    "phone": "+1202-555-0107",*/
      return (
        <section className = 'location-customizer-panel'>
          <div className = 'title'>
            Edit GHL Location
          </div>
          <Input
            name = 'business-name'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.businessName = event.target.value;
              state.location.business.name = event.target.value;
              this.setState(state);
            }}
            placeholder = 'Business Name'
            value = {this.state.location.business.name}
          ></Input>
          <Input
            name = 'address'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.address = event.target.value;
              this.setState(state);
            }}
            placeholder = 'Address'
            value = {this.state.location.address}
          ></Input>
          <Input
            name = 'city'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.city = event.target.value;
              this.setState(state);
            }}
            placeholder = 'City'
            value = {this.state.location.city}
          ></Input>
          <Input
            name = 'postal-code'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.postalCode = event.target.value;
              this.setState(state);
            }}
            placeholder = 'Postal Code'
            value = {this.state.location.postalCode}
          ></Input>
          <Input
            name = 'state'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.state = event.target.value;
              this.setState(state);
            }}
            placeholder = 'State'
            value = {this.state.location.state}
          ></Input>
          <Input
            name = 'country'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.country = event.target.value;
              this.setState(state);
            }}
            placeholder = 'Country'
            value = {this.state.location.country}
          ></Input>
          <Input
            name = 'website'
            type = 'text'
            onChange = {(event) => {
              const state = Object.assign({}, this.state);
              state.location.website = event.target.value;
              this.setState(state);
            }}
            placeholder = 'Website'
            value = {this.state.location.website}
          ></Input>
          <Button
            value = 'Update Location'
            onClick = {() => {
              this.updateLocationInGoHighLevel();
            }}
          ></Button>
          <Outlet />
        </section>
      );
    }
  }
  export default LocationCustomizerPanel;