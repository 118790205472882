import React from 'react';
import { Outlet } from 'react-router-dom';
import ForgotPasswordForm from './components/ForgotPasswordForm.js';

class ForgotPasswordApp extends React.Component {
    render() {
        return (
            <div className = 'forgot-password-app'>
                <ForgotPasswordForm
                ></ForgotPasswordForm>
                <Outlet />
            </div>
        )
    }
}
export default ForgotPasswordApp;